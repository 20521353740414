// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------
@media (max-width: 992px) {
    .mobile-header__panel {
        color: #3d464d;
    }
    // .mobile-header__search {
    //     display: none;
    // }
    .mobile-header__menu-button {
        fill: #fff;
    }
    .mobilemenu__header {
        background: #3366cc;
        border-bottom: 1px solid white;
    }
    .mobilemenu__close {
        fill: #ffffff;
    }
    .mobilemenu__title {
        display: flex;
        justify-content: space-between;
        span {
            cursor: pointer;
            padding: 15px 10px;
            color: #ffffff;
        }
        .active {
            background-color: white;
            color: #3d464d;
        }
    }
}
.addresses-list__item--new {
    .under-list {
        cursor: pointer;
    }
}
.modal-footer {
    justify-content: space-between;
}
.modal-header .close {
    padding: 0.5rem;
    color: #fff;
    background-color: #333;
    opacity: 0.9;
    margin: -1rem -1rem -1rem auto;
}
.modal-header .close:hover {
    opacity: 1 !important;
}
.order-success__header {
    padding-top: 10px;
}
.site-footer {
    background-color: #3d464d;
    color: white;
}
.footer-newsletter__form-button:focus,
.footer-newsletter__form-button:active,
.footer-newsletter__form-button:hover {
    background-color: #333;
}
.totop__button:hover,
.totop__button:active {
    background-color: #333;
}
@media (max-width: 991px) {
    .account-nav__item--active a {
        background-color: #6c757d !important;
        color: white;
        font-weight: 500;
        border-color: #3366cc;
    }
}
.show-sm {
    display: none;
}
@media (max-width: 767px) {
    .order-success__header {
        padding: 0 0 30px 0;
    }
    .show-sm {
        display: block;
    }
    .hide-feature {
        display: none;
    }
}
@media (max-width: 410px) {
    .hide-xs {
        display: none;
    }
}
/* width */
::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #586670;
    border-radius: 3px;
}

/* Handle on hover #272d31; */
::-webkit-scrollbar-thumb:hover {
    background: #3775f0;
}
.typography {
    font-size: 17px;
    ul,
    ol {
        list-style-position: outside;
        list-style-type: square;
    }
}
.block-header__divider {
    background: #3366cc;
}
.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}
.react-player {
    position: absolute;
    top: 0;
    left: 0;
}
.play-btn {
    display: inline-block;
    height: 80px;
    width: 80px;
    line-height: 85px;
    border-radius: 50%;
    padding: 20px 27px;
    background-color: transparent;
    // border: 3px solid #3d464d;
    border-style: dashed;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    svg {
        fill: #3d464d;
    }
}
.play-btn:hover {
    background-color: #3366cc;
    border: 3px solid #3366cc;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    svg {
        fill: #ff3333;
    }
}
.play-btn:focus,
button:focus {
    outline: 0 !important;
}
.social-links__link--type--instagram {
    background: #f09433;
    background: -moz-linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
    );
    background: -webkit-linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
    );
    background: linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
    );
}
.whatapp {
    position: fixed;
    right: 0;
    bottom: 0;
    margin-bottom: 20px;
    margin-right: 124px;
    z-index: 10;
    .rounded-whatapp {
        border-radius: 50% !important;
        width: 55px;
        height: 55px;
        padding-top: 13px;
        padding-right: 36px;
        i {
            font-size: 24px;
        }
    }
}
#invoice {
    background-color: #fefeff;
    padding: 40px;
    margin-bottom: 70px;
}

.invoice {
    position: relative;
    background-color: #fefeff;
    min-height: 680px;
    padding: 15px;
}

.invoice header {
    padding: 10px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #3366cc;
}

.invoice .company-details {
    text-align: right;
}

.invoice .company-details .name {
    margin-top: 0;
    margin-bottom: 0;
}

.invoice .contacts {
    margin-bottom: 20px;
}

.invoice .invoice-to {
    text-align: left;
}

.invoice .invoice-to .to {
    margin-top: 0;
    margin-bottom: 0;
}

.invoice .invoice-details {
    text-align: right;
}

.invoice .invoice-details .invoice-id {
    margin-top: 0;
    color: #3366cc;
}

.invoice main {
    padding-bottom: 50px;
}

.invoice main .thanks {
    margin-top: -100px;
    font-size: 2em;
    margin-bottom: 50px;
}

.invoice main .notices {
    padding-left: 6px;
    border-left: 6px solid #3366cc;
}

.invoice main .notices .notice {
    font-size: 1.2em;
}

.invoice table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 20px;
}

.invoice table td,
.invoice table th {
    padding: 15px;
    background: #eee;
    border-bottom: 1px solid #fff;
}

.invoice table th {
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
}

.invoice table td h3 {
    margin: 0;
    font-weight: 400;
    color: #3366cc;
    font-size: 1.2em;
}

.invoice table .qty,
.invoice table .total,
.invoice table .unit {
    text-align: right;
    font-size: 1.2em;
}

.invoice table .no {
    color: #fff;
    font-size: 1.6em;
    background: #3366cc;
}

.invoice table .unit {
    background: #ddd;
}

.invoice table .total {
    background: #3366cc;
    color: #fff;
}

.invoice table tbody tr:last-child td {
    border: none;
}

.invoice table tfoot td {
    background: 0 0;
    border-bottom: none;
    white-space: nowrap;
    text-align: right;
    padding: 10px 20px;
    font-size: 1.2em;
    border-top: 1px solid #aaa;
}
.printd {
    font-weight: bold;
}
.invoice table tfoot tr:first-child td {
    border-top: none;
}

.invoice table tfoot tr:last-child td {
    color: #3366cc;
    font-size: 1.6em;
    font-weight: bold;
    border-top: 1px solid #3366cc;
}

.invoice table tfoot tr td:first-child {
    border: none;
}

.invoice footer {
    width: 100%;
    text-align: center;
    color: #777;
    border-top: 1px solid #aaa;
    padding: 8px 0;
}
.grecaptcha-badge {
    visibility: collapse !important;
}
@media print {
    .invoice {
        font-size: 11px !important;
        overflow: hidden !important;
        padding: 50px !important;
    }

    .invoice footer {
        // position: absolute;
        // bottom: 10px;
        page-break-after: always;
    }

    .invoice > div:last-child {
        page-break-before: always;
    }
}
.top-alert {
    position: fixed;
    top: 0;
    width: 100%;
    height: 40px;
    line-height: 15px;
    z-index: 999;
    background-color: #3d464d;
    color: #ffffff;
    box-shadow: 0 2px 8px #f0f1f2;
    &-holder {
        position: relative;
        height: 100%;
    }
    &-inner {
        display: flex;
        align-items: center;
        height: 100%;
        .txt-reveal {
            margin-left: 5px;
        }
        @media (max-width: 435px) {
            .txt-reveal {
                display: none;
            }
        }
    }
    @media (max-width: 325px) {
        font-size: 14px;
    }
    .btn-top {
        display: inline;
        border-radius: 2px;
        transition: all 0.1s ease-in-out;
        font-size: 0.9rem;
        line-height: 0.9rem;
        padding: 5px 5px;
        font-weight: 500;
        border: none;
        background: #3366cc;
        color: #fff;
        fill: #fff;
        margin-left: 6px;
    }
    &-close {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        background: #be2121;
        color: #fff;
        fill: #fff;
        outline: none;
        height: 100%;
        font-size: 24px;
        line-height: 24px;
        padding: 2px 15px;
        text-decoration: none;
    }
}
.pay-checked {
    fill: #3366cc;
}
.pointer {
    cursor: pointer;
}
.flex {
    display: flex;
}
.flex-1 {
    flex: 1;
}
.h-50px {
    height: 50px;
}
.align-center {
    align-items: center;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.justify-around {
    justify-content: space-around;
}
